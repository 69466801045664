import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosBulb, IoIosMan, IoIosStar } from "react-icons/io";
import { BsFillShieldFill } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				The Burrow Tattoo
			</title>
			<meta name={"description"} content={"Imagine. Ink. Inspire. Come to The Burrow Tattoo, where your stories are painted in ink."} />
			<meta property={"og:title"} content={"The Burrow Tattoo"} />
			<meta property={"og:description"} content={"Imagine. Ink. Inspire. Come to The Burrow Tattoo, where your stories are painted in ink."} />
			<meta property={"og:image"} content={"https://luminaraco.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luminaraco.com/images/0032342.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://luminaraco.com/images/0032342.png"} />
			<meta name={"msapplication-TileImage"} content={"https://luminaraco.com/images/0032342.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="60vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://luminaraco.com/images/1.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			/>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Welcome to The Burrow Tattoo!
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					The Burrow Tattoo Tattoo Parlor invites you into a world where ink and imagination merge seamlessly. Our studio is your space to explore and express your stories through beautiful, lasting art. For newcomers and tattoo enthusiasts alike, our doors are open to craft your next piece with precision and passion.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--green"
					sm-text-align="left"
				>
					<Strong color="--primary">
						Discover the tattoo world
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Why The Burrow Tattoo Stands Out
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosStar}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Artistic Excellence
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Our artists are celebrated for their artistic versatility and meticulous attention to detail.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosStar}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Personalized Experience
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							We work closely with you to craft designs that reflect your personality and aspirations.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosStar}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Uncompromised Quality
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Using the latest equipment and highest standards of hygiene, we guarantee a top-tier tattooing experience.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="bs"
							icon={IoIosStar}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Relaxed Atmosphere
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Our parlor is designed to ensure you feel at ease, making your tattoo experience as enjoyable as it is memorable.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});